"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractAssetType = exports.VideoSource = exports.ProgressiveHls = exports.Progressive = void 0;
const typebox_1 = require("@sinclair/typebox");
const asset_model_1 = require("../asset/asset-model");
const VideoSourceBase = typebox_1.Type.Object({
    drmEnabled: typebox_1.Type.Optional(typebox_1.Type.Boolean({
        title: 'Is DRM protected',
        description: 'If true, the video will be protected by a DRM license. Digital Rights Management (DRM) is a digital license system that allows content creators and distributors to control who can use their content, and how they can use it. ',
        default: false,
    })),
    serversideForensic: typebox_1.Type.Optional(typebox_1.Type.Boolean({
        title: 'Has Serverside Forensic',
        description: 'If checked, the video is protected by Serverside Forensic Watermarking.',
        default: false,
    })),
    assetId: typebox_1.Type.Optional(typebox_1.Type.String({
        title: 'Asset Id',
        description: 'Asset Id from the asset manager',
    })),
    assetType: typebox_1.Type.Optional(asset_model_1.AssetType),
    thumbnail: typebox_1.Type.Optional(typebox_1.Type.String({
        title: 'Thumbnail',
        description: 'Thumbnail for the video',
    })),
});
const Hls = typebox_1.Type.Composite([
    typebox_1.Type.Object({ hls: typebox_1.Type.String({ title: 'HLS' }) }),
    VideoSourceBase,
]);
const Dash = typebox_1.Type.Composite([
    typebox_1.Type.Object({ dash: typebox_1.Type.String({ title: 'DASH' }) }),
    VideoSourceBase,
]);
const HlsDash = typebox_1.Type.Composite([
    typebox_1.Type.Object({ hls: typebox_1.Type.String({ title: 'HLS' }) }),
    Dash,
]);
exports.Progressive = typebox_1.Type.Composite([
    typebox_1.Type.Object({ progressive: typebox_1.Type.String({ title: 'Progressive' }) }),
    typebox_1.Type.Pick(VideoSourceBase, ['assetId', 'assetType', 'thumbnail']),
]);
exports.ProgressiveHls = typebox_1.Type.Composite([
    typebox_1.Type.Object({ hls: typebox_1.Type.String({ title: 'HLS' }) }),
    exports.Progressive,
]);
exports.VideoSource = typebox_1.Type.Union([
    Dash,
    Hls,
    HlsDash,
    exports.Progressive,
    exports.ProgressiveHls,
]);
/**
 * Extract `AssetType` from a `JsonObject` that looks like a `VideoSource`
 * record. If `assetType` is in the record use that otherwise attempt to infer
 * the `assetType` from the details of the the `VideoSource`.
 */
function extractAssetType(source) {
    if ('assetType' in source && isAssetType(source.assetType)) {
        return source.assetType;
    }
    else if ('serversideForensic' in source &&
        source.serversideForensic === true) {
        return 'stream-watermark';
    }
    else if ('drmEnabled' in source && source.drmEnabled === true) {
        return 'stream-encrypted';
    }
    else if ('dash' in source && typeof source.dash === 'string') {
        return 'stream';
    }
    else if ('hls' in source &&
        typeof source.hls === 'string' &&
        !('progressive' in source)) {
        return 'stream';
    }
    else if ('progressive' in source &&
        typeof source.progressive === 'string') {
        return 'video';
    }
    return undefined;
}
exports.extractAssetType = extractAssetType;
/**
 * Determine if the given `value` is in the set of know `AssetType` literals.
 */
function isAssetType(value) {
    const validTypes = new Set(asset_model_1.AssetType.anyOf.map((s) => s.const));
    return typeof value === 'string' && validTypes.has(value);
}
